import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Dear TuringTrader Member,`}</p>
    <p>{`We wish you a happy, healthy, and prosperous 2024. The new year is always a great time to reflect on the past and make plans and resolutions for the year ahead. `}<em parentName="p">{`TuringTrader`}</em>{` is no different here, and I'd like to use the opportunity to review our 2023 performance.`}</p>
    <p>{`2023 was another difficult year. Not because the markets didn't go up, but because they changed direction multiple times, with strong momentum. Under such conditions, tactical strategies easily suffer from whipsaws, and `}<em parentName="p">{`TuringTrader`}</em>{` is no exception here.`}</p>
    <p>{`Nonetheless, I believe our strategies did very well. `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-buoy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`Buoy`}</a>{` was once more the star, far outperforming the aggregate bond market. When considering 2022 and 2023 together, it becomes clear that our meta portfolios, especially `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-buoy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`All-Stars XL`}</a>{`, also did exactly what it was designed to do: performance comparable to a 60/40, but with less volatility and reduced downside.`}</p>
    <p>{`Of course, there are aspects that didn't work that well. First and foremost, our aggressive strategies suffered from `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-round-robin/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`Round Robin`}</a>{`'s bet on energy throughout Q4/2024. And while this led to some underperformance, it is worth noting the strategy was still making money, and that our balanced meta portfolios, including `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-all-stars-xl/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`All-Stars XL`}</a>{`, were able to mostly remedy this situation. This is, in no small part, due to `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-buoy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`Buoy`}</a>{`'s great performance.`}</p>
    <p>{`These results should serve as a stark reminder that tactical strategies, like any other investment, greatly benefit from diversification. This is exactly `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-buoy/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`why we created our All-Stars family`}</a>{` of portfolios.`}</p>
    <ButtonPrimary text="Read 2023 Review" to="https://www.turingtrader.com/articles/review-2023/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024" mdxType="ButtonPrimary" />
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It is an honor to have you on our site. If you like what we do, support our growth by `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/company/referral-program/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-january-2024"
      }}>{`sharing with your friends`}</a>{` how `}<em parentName="p">{`TuringTrader`}</em>{` adds value for your investing.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}<br /></p>
    <br />
    <br />
Felix<br />
--<br />
Felix Bertram

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      